import http from "@/utils/http";

export async function create_user(data) {
    const req = (await http.post('/user',data));
    return req.data;   
}

export async function delete_user(id) {
    console.log('delete id:',id)
    const req = (await http.delete(`/user/${id}`));
    return req.data;   
}

export async function update_user(id,data) {
    console.log('put data:',data)
    const req = (await http.put(`/user/${id}`,data));
    return req.data;   
}

export async function get_users(data) {
    const req = (await http.get('/user/filter',{params:data}));
    return req.data;   
}

export async function get_user_info(id){
    const req = (await http.get(`/user/info/${id}`));
    return req.data;
}