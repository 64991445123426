<template>
    <b-modal :visible="show" size="md" centered hide-header hide-footer hide-header-close>
        <div class="p-2">
            <div class="text-center">
                <app-heading-1>{{heading}}</app-heading-1>
            </div>
            <div class="mt-2 text-center">
                <slot></slot>
            </div>
            <div class="mt-2 d-flex justify-content-center">
                <button class="btn bg-light-danger text-danger fw-bolder mr-2 confirm-btn" @click="()=>$emit('onConfirm')">{{confirmText}}</button>
                <button class="btn btn-primary" @click="()=>show=false">{{cancelText}}</button>
                <!-- <app-simple-button variant="ligth-danger" @click="()=>$emit('onConfirm')">Yes</app-simple-button>
                <app-simple-button variant="primary"  @click="()=>show=false">{{cancelText}}</app-simple-button>     -->
            </div>
        </div>
    </b-modal>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import { BModal, VBModal } from 'bootstrap-vue'
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
export default{
    components: {
        AppHeading1,
        AppSimpleButton,
        BModal,
        VBModal,
    },
    props:{
        heading:[String],
        cancelText:[String],
        confirmText:[String],
    },
    data(){
        return{
            show:false
        }
    },
    methods:{
        openModal(){
            this.show = true;
        },
        hideModal(){
            this.show = false;
        }
    }
}
</script>
<style scoped>
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(234,86,87,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(234,86,87,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(234,86,87,0.35);
}
</style>