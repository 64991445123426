import http from "@/utils/http";

export async function invoice_backup_list(filters) {
    const req = (await http.get('/invoice/backup/filter',{params:filters}));
    return req.data;   
}

export async function documents_backup_list(filters){
    const req = (await http.get('/documents/backup/filter',{params:filters}));
    return req.data;
}

export async function delete_backup(type,data){
    const req = (await http.delete(`/${type}/backup`,{data}));
    return req.data;
}