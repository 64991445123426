<template>
    <div class="heading-4">
        <slot></slot>
    </div>
</template>
<script>
export default{
    data(){
        return{
            //
        }
    }
}
</script>
<style scoped>
 .heading-4{
        color: #4B465C;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
    }
</style>