<template>
    <div>
        <app-paginated-table :fields="getFields" :items="getItems" :current-page="current_page" :total-pages="total_pages" :total="total_records" :loading="loading" @onPageChange="onPageChange" :mobileHeaders="currentTab==0?['document_name', 'delete']:['attachment','invoiceNo','amount','delete']" @onLoadMore="loadMoreItems">
            <template #header>
                <b-tabs @input="onTabChange">
                    <b-tab title="Documents" value="documents" active></b-tab>
                    <b-tab title="Invoices" value="invoices"></b-tab>
                </b-tabs>   
            </template>
            <template #filters>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="onSearch()"></app-text-input>
                    </div>
                </div>
            </template>
            <template #head(delete)>
                {{ '' }}
            </template>
            <template #cell(customers)="data">
                <b-avatar-group size="32px">
                    <b-avatar
                    :class="[data.item.collaborators.length<2?'mx-0':'']" v-for="(c,index) in data.item.collaborators.slice(0,1)" :key="index"
                    :text="c.email.slice(0,1).toUpperCase()"
                    :variant="colors[index]"
                    v-b-tooltip.hover.v-primary="c.email"
                  />
                  <b-avatar v-if="data.item.collaborators.length > 1" 
                    class="mx-0" 
                    :text="`+${data.item.collaborators.length-1}`"
                    variant="light-success"
                    id="tooltip-avatar"
                    v-b-tooltip.hover.v-primary="getcollaborators(data.item.collaborators.slice(1))"

                    />
                    <!-- <b-tooltip target="tooltip-avatar" variant="primary"><span v-for="(c,index) in data.item.collaborators.slice(1)" :key="index">{{c.email}}<br></span></b-tooltip> -->
                </b-avatar-group>
            </template>
            <template #cell(document_name)="data">
                <div class="d-flex align-items-center">
                    <img src="@/assets/images/app-icons/pdf.png" alt="">
                    <span class="ml-1">{{ data.item.documentName }}</span>
                </div>
            </template>
            <template #cell(attachment)="data">
                <img :src="require('@/assets/images/app-icons/pdf.png')" alt="">
            </template>
            <template #cell(support_tickect)="data">
                {{ data.item.support_tickect?data.item.support_tickect:'N/A' }}
            </template>
            <template #cell(delete)="data">
                <img src="@/assets/images/app-icons/delete.png" class="cursor-pointer" alt="" @click="deleteFiles(data.item._id)">
                
            </template>
            <template #cell(date_uploaded)="data">
                {{ getFormatedDate(data.item.createdAt,"DD MMM YYYY") }}
            </template>
            <template #cell(last_modified)="data">
                {{ getFormatedDate(data.item.updatedAt,"DD MMM YYYY") }}
            </template>
        </app-paginated-table>
        
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown, BDropdownItem, BBadge,BSidebar, BAvatarGroup, BAvatar, VBTooltip, BTooltip} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue'
import UpdateUser from '@/components/functional-components/admin/users/UpdateUser.vue'
import UserDetails from '@/components/functional-components/admin/users/UserDetails.vue'
import PendingSignatures from '@/components/functional-components/other/invoice/PendingSignatures.vue'
import {invoice_backup_list, documents_backup_list, delete_backup} from '@/apis/others/backups'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
export default{
    directives:{
        'b-tooltip':VBTooltip,
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        BBadge,
        AppSelectBox,
        AppConfirmModal,
        UpdateUser,
        BSidebar,
        UserDetails,
        BAvatarGroup, 
        BAvatar,
        PendingSignatures,
        BTooltip
    },
    data(){
        return{
            search:'',
            document_fields:[
                {
                    key:'document_name',
                    label:'Document Name'
                },
                {
                    key:'date_uploaded',
                    label:'Date Uploaded'
                },
                {
                    key:'last_modified',
                    label:'Last Modified'
                },
                {
                    key:'delete',
                }
            ],
            invoice_fields:[
                {
                    key:'invoiceNo',
                    label:'Invoice #'
                },
                {
                    key:'description',
                    label:'Description'
                },
                {
                    key:'amount',
                    label:'Amount'
                },
                {
                    key:'customers',
                    label:'Customers',
                },
                {
                    key:'attachment',
                    label:'Attachment'
                },
                {
                    key:'delete'
                }
            ],
            invoices: [],
            documents:[],
            filters:{
                page:1,
                limit:10,
                search:'',
            },
            current_page:1,
            total_records:0,
            total_pages:0,
            loading:false,
            currentTab:0,
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            loadMore:false,
        }
    },
    mounted(){
        // this.getUsers()
        this.getDocumentsList()
    },
    computed:{
        getFields(){
            let fields=[]
            fields=this.currentTab==0?this.document_fields:this.invoice_fields
            return fields
        },
        getItems(){
            let items=[]
            items=this.currentTab==0?this.documents:this.invoices
            return items
        },

    },
    methods:{
        onTabChange(tab){
            this.currentTab=tab
            this.filters.search=''
            if(tab==0){
                this.getDocumentsList()
            }else{
                this.getInvoicesList()
            }
            this.current_page=1
            this.total_records=0
            this.total_pages=0
            // this.getUsers()
        },
        onPageChange(page){
            this.filters.page=page
            if(this.currentTab==0){
                this.getDocumentsList()
            }else{
                this.getInvoicesList()
            }
            
        },
        getInvoicesList(){
            this.loading=true
            invoice_backup_list(this.filters).then(resp=>{
                this.loading=false
                this.invoices=this.loadMore?[...this.invoices,...resp.data.backupInvoices]:resp.data.backupInvoices 
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.backupInvoicesCount
                this.total_pages=resp.data.backupInvoicesCount>0?resp.data.backupInvoicesCount/this.filters.limit:0
                this.loadMore=false
            }).catch(err=>{
                this.loading=false
            })
        },
        getDocumentsList(){
                this.loading=true
                documents_backup_list(this.filters).then(resp=>{
                    this.loading=false
                    this.documents=this.loadMore?[...this.documents,...resp.data.backupDocuments]:resp.data.backupDocuments
                    this.current_page=Number(resp.data.currentPage)
                    this.total_records=resp.data.backupDocumentsCount
                    this.total_pages=resp.data.backupDocumentsCount>0?resp.data.backupDocumentsCount/this.filters.limit:0
                    this.loadMore=false
                }).catch(err=>{
                    this.loading=false
                })
            },
            getFormatedDate(date,format){
                return moment(date).format(format)
            },
            onSearch(){
                if(this.currentTab==0){
                    this.getDocumentsList()
                }else{
                    this.getInvoicesList()
                }
            },
            deleteFiles(id){
                let type= this.currentTab==0?'documents':'invoice'
                delete_backup(type,{ids:[id]}).then(resp=>{
                    this.$bvToast.toast(resp.message, {
                        title: 'Deleted',
                        variant: 'success',
                        solid: true,
                    })
                    if(this.currentTab==0){
                        this.getDocumentsList()
                    }else{
                        this.getInvoicesList()
                    }
                })
            },
            getcollaborators(collaborators){
                let colab=''
                collaborators.map(c=>{
                    colab=`${colab}${c.email}\n`
                });
                return colab
            },
            loadMoreItems(){
                this.loadMore=true;
                this.filters.page+=1
                if(this.currentTab==0){
                    this.getDocumentsList()
                }else{
                    this.getInvoicesList()
                }
            }
        },


}
</script>