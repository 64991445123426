<template>
    <div class=" p-1 ">
        <div class="d-flex align-items-center  p-1" @click="toggle()">
                <feather-icon :icon="show?'ChevronDownIcon':'ChevronRightIcon'" size="21" class="mr-1" ></feather-icon>
                <slot name="headers" class=""></slot>
           
        </div>
        <b-collapse v-model="show">
            <slot name="body"></slot>
        </b-collapse>
    </div>
</template>
<script>
import {BCollapse} from 'bootstrap-vue'
export default {
    components: {
        BCollapse
    },
    data(){
        return{
            show:false
        }
    },
    methods:{
        toggle(){
            this.show =!this.show
        }
    }

}
</script>
<style scoped>
    .rotate{
        transform: rotate(90deg);
    }
</style>