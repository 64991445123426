<template>
    <div class="paginated-table bg-white bg-transparent">
        <div class="row align-items-center">
            <div class="col-12">
                <slot name="title"></slot>
            </div>
            <div class="col-8">
                <slot name="header"></slot>
            </div>
            <div class="col-4  d-flex  justify-content-end text-right pr-3">

                <b-link class="d-flex justify-content-end align-items-center mr-1" @click="showFilters()" v-if="!noFilters">
                    <feather-icon
                        icon="FilterIcon"
                        size="18"
                        class="mr-8"
                    />
                    <app-normal-text>
                        Filters
                    </app-normal-text>
                </b-link>
                <slot name="place-right" ></slot>
            </div>
        </div>
        <div class="filters" v-if="showFilter">
            <span class="text-headings">Search Filter</span>
            <slot name="filters"></slot>
        </div>
        <div class="row">
            <div class="col-12 text-nowrap d-none d-md-block">
                <b-table class="text-nowrap" responsive :fields="fields" :items="items" borderless empty-text :busy="loading" hover @row-clicked="(e)=>$emit('onRowClicked',e)">
                    <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                        <slot :name="slotName" v-bind="slotScope"></slot>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle mx-1"></b-spinner>
                          <strong>Fetching Data....</strong>
                        </div>
                      </template>
                </b-table>

                <div v-if="!items.length && !loading" class="text-center mb-2">No record found</div>
            </div>
            
            <div class="col-12 d-md-none d-block">
                <div v-if="!items.length && loading" class="d-flex justify-content-center my-2" ><b-spinner variant="primary" class="mr-1" label="Spinning"></b-spinner> Loading</div>
                <div v-if="!items.length && !loading" class="text-center mb-2">No record found</div>
                <app-collapse v-for="(item,index) in items" :key="index">
                    <template #headers>
                        <div  v-for="(header,index) in mobileHeaders" :key="header" class="text-small"  :class="[index==mobileHeaders.length-placeRight-1?'mr-auto':'mr-1']">
                            <slot :name="`cell(${mobileHeaders[index]})`"  :item="item" >
                                <!-- Default content if no slot content is provided -->
                                <span class="text-small">{{mobileHeaders[index]=='amount'?`$${item[mobileHeaders[index]]}`:item[mobileHeaders[index]] }}</span>
                            </slot>
                        </div>
                    </template>
                    <template #body>
                        <div class="row ml-2 m-8 inner-card">
                            <div class="col-6 mx-0 m-8" v-for="(field, index) in fields" :key="index" v-if="!mobileHeaders.includes(field.key)">
                                    <div >{{field.label}}</div>
                                    <div>
                                        <!-- {{item[field.key]}} -->
                                        <slot :name="'cell(' + field.key + ')'" :field="field" :item="item">
                                            <!-- Default content if no slot content is provided -->
                                            {{ item[field.key] }}
                                          </slot>
                                    </div>         
                            </div>
                        </div>
                    </template>
                </app-collapse>
                <div class="p-2" v-show="items.length && showLoadMore">
                    <app-simple-button variant="primary" @click="loadMoreData" :loading="loadMore">Load More</app-simple-button>
                </div>
            </div>
        </div>
        <div class="d-md-flex d-none justify-content-between  px-2 my-1" v-if="items.length">
            <div>
                Showing {{ ((Number(currentPage)-1)*10)+1 }} to {{ (Number(currentPage)-1)*10+items.length }} of {{ total }} entries
            </div>
            <div class="">
                <b-pagination size="lg" :value="currentPage" :total-rows="total"
                :per-page="10" @change="getNewRecords">
                </b-pagination>
            </div>
        </div>
        
    </div>
</template>
<script>
import {BTable, BLink, BPagination,BBadge, BSpinner} from 'bootstrap-vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppCollapse from '@/components/app-components/AppCollapse.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
export default{
    components: {
        BTable,
        BLink,
        AppNormalText,
        BPagination,
        AppHeading1,
        BSpinner,
        AppCollapse,
        BBadge,
        AppSimpleButton
        
    },
    props:{
        fields:[Array],
        items:{
            type:[Array],
            default:[]
        },
        currentPage:[Number, String],
        totalPages:[Number, String],
        total:[Number, String],
        loading:[Boolean],
        mobileHeaders:[Array],
        noFilters:[Boolean],
        placeRight:{
            type:[Number],
            default:1
        }
    },
    data(){
        return{
            showFilter:false,
            loadMore:false
        }
    },
    computed:{
        showLoadMore(){
            return this.currentPage*10<this.total
        }
    },
    methods:{
        showFilters(){
            this.showFilter=!this.showFilter
        },
        getNewRecords(page){
            this.$emit('onPageChange',page)
        },
        onRowSelected(row){
            console.log('selected row:', row)
        },
        loadMoreData(){
            this.loadMore=true
            this.$emit('onLoadMore')
        }
    }
}
</script>
<style scoped>
.paginated-table{
    border-radius: 6px;
}
.text-headings{
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
}
.text-small{
    font-size: 16px;
    font-weight: 500;
    line-height:15px;
    color: #4B465C;
  }
.filters{
    background: #ebf1ff;
    padding: 16px;
    color:#4B465C;
}
.px-16{
    padding: 16px;
}
.mr-8{
    margin-right: 8px;
}
.m-8{
    margin: 8px;
}
.inner-card{
    border-radius: 6px;
    background-color: #F8FAFC;
    padding: 12px;
}
.w-80{
    width: 80% !important;
}
.w-10{
    width: 10% !important;
}
</style>